import { BASE_URL } from '@config'
/** 参数列表
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 *参数列表-获取参数列表
 */
export const GET_PARAMS_LIST = {
    url: `${BASE_URL}/product-spec`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *参数列表-获取参数列表(已启用的)
 */
export const GET_OPEN_PARAMS_LIST = {
    url: `${BASE_URL}/product-spec/relevance`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 *参数列表-增加参数
 */
export const ADD_PARAM = {
    url: `${BASE_URL}/product-spec/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *参数列表-增加参数
 */
export const EDIT_PARAM = {
    url: `${BASE_URL}/product-spec/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *参数列表-删除参数列表
 */
export const DELETE_PARAM = {
    url: `${BASE_URL}/product-spec/del`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 *参数列表-启用或关闭参数
 */
export const OPEN_OR_CLOSE_PARAM_SWITCH = {
    url: `${BASE_URL}/product-spec/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
