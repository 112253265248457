import axios from '@service'
import { GET_ORDER_INFO_RISK_CONTROL_INFO } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/**
 * 任务详情-查看风控信息
 */
export default async function getOrderInfoRiskControlInfoService(payload = {}, config = {}) {
    const param = {
        ...GET_ORDER_INFO_RISK_CONTROL_INFO,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
