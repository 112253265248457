import axios from '@service'
import { CHARGEBACK_APPLY } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/**
 * 风控采购订单 退单申请
 * */
export default async function chargebackApplyService(payload = {}, config = {}) {
    const param = {
        ...CHARGEBACK_APPLY,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
