import { BASE_URL } from '@config'

/**
 * 风控采购
 * 提交订单
 * 工程投标保证保险采购
 * */

// 工程投标保证保险采购详情回显
export const GET_TENDER_STOCK = {
    url: `${BASE_URL}/tender-stock/getChannelOrder`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 工程投标保证保险采购暂存
export const SAVE_TENDER_STOCK = {
    url: `${BASE_URL}/tender-stock/cache`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 工程投标保证保险采购提交
export const SUBMIT_TENDER_STOCK = {
    url: `${BASE_URL}/tender-stock`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 工程投标保证保险采购提交
export const EDIT_TENDER_STOCK = {
    url: `${BASE_URL}/tender-stock/modify`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}


