import axios from '@service'
import { GET_UNDER_WRITE_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'
// 获取承保导入记录列表
export default async function getImportUnderwriteListService(payload = {}, config = {}) {
    const param = {
        ...GET_UNDER_WRITE_LIST,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }

}
