import { BASE_URL } from '@config'

/** 企业
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 企业列表
 */
export const GET_ENTERPRISE_LIST = {
    url: `${BASE_URL}/enterprise`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 企业列表-企业详情
 */
export const GET_ENTERPRISE_INFO = {
    url: `${BASE_URL}/enterprise/queryEnterpriseDetail/`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 企业详情-财报信息
 */
// 财报信息列表
export const GET_FINANCIAL_LIST = {
    url: `${BASE_URL}/enterprise/selectFinanceById`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 资产负债表
export const GET_BALANCE_SHEET = {
    url: `${BASE_URL}/balance-sheet/queryFinance`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 利润表
export const GET_PROFIT_SHEET = {
    url: `${BASE_URL}/income-statement/queryIncome`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 现金流量表
export const GET_CASH_FLOW_SHEET = {
    url: `${BASE_URL}/cash-flow-statement/queryCash`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 财务指标分析表
export const GET_FINANCIAL_ANALYSIS = {
    url: `${BASE_URL}/financialAnalysis/queryfinanceAnalysis`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 财报信息附件
export const GET_FINANCIAL_FILE = {
    url: `${BASE_URL}/risk-order-extended/queryFinanceFile`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 财报信息附件下载
export const DOWN_LOAD_FINANCIAL_FILE = {
    url: `${BASE_URL}/risk-order-extended/downLoadFinanceFile`,
    method: 'get',
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


/**
 * 企业详情-征信报告列表
 */
// 征信报告列表
export const GET_CREDIT_REPORT_LIST = {
    url: `${BASE_URL}/enterprise/selectCreditReportById`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 征信报告
export const GET_CREDIT_REPORT = {
    url: `${BASE_URL}/enterprise-credit-report/getEnterpriseCreditReport`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 征信报告 scc
export const GET_CREDIT_REPORT_SCC = {
    url: `${BASE_URL}/enterprise-credit-report/getCreditReportByScc`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

// 征信报告附件资料
export const GET_CREDIT_REPORT_FILE = {
    url: `${BASE_URL}/risk-order-extended/queryCreditReportFile`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 征信报告附附件下载
export const DOWN_LOAD_CREDIT_REPORT_FILE = {
    url: `${BASE_URL}/risk-order-extended/downLoadCreditReportFile`,
    method: 'get',
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


/**
 * 企业详情-订单列表
 */
export const GET_ORDER_LIST = {
    url: `${BASE_URL}/enterprise/selectRiskOrderById`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 风控信息 企业征信报告
export const GER_ENTERPRISE_REPORT = {
    url: `${BASE_URL}/enterprise-credit-report/getCreditReportBy`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
