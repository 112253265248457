import { BASE_URL } from '@config'

/**
 * 全部订单列表
 * */
export const GET_ALL_ORDER_LIST = {
    url: `${BASE_URL}/channel-risk-control`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
/**
 * 全部订单列表导出
 * */
export const EXPORT_ALL_ORDER_LIST = {
    url: `${BASE_URL}/channel-risk-control/channelExport`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json;'
    },
    responseType: 'blob',
    timeout: 30000
}
// 导出承保结果
export const EXPORT_UNDER_WRITE_LIST = {
    url: `${BASE_URL}/channel-risk-control/insuranceRecordExport`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json;'
    },
    responseType: 'blob',
    timeout: 30000
}
// 导入承保结果zip
export const IMPORT_UNDER_WRITE = {
    url: `${BASE_URL}/channel-risk-control/batchImport`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// 导入承保结果zip
export const GET_UNDER_WRITE_LIST = {
    url: `${BASE_URL}/batch-underwriting-import-records`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 风控采购详情
 * */
export const GET_ORDER_INFO = {
    url: `${BASE_URL}/channel-risk-control/queryDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 提交承保结果
 * */
export const SUBMIT_UNDERWRITING_ESULT = {
    url: `${BASE_URL}/channel-order-policy-form/insuredOrNotInsured`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
/**
 * 下载报告
 * */
export const DOWN_LOAD_PDF = {
    url: `${BASE_URL}/channel-risk-control/downLoadPdf`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
/**
 * 退单申请
 * */
export const CHARGEBACK_APPLY = {
    url: `${BASE_URL}/order/chargebackApply`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
/**
 * 退单审核
 * */
export const CHARGEBACK_AUDIT = {
    url: `${BASE_URL}/order/chargebackAudit`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
/**
 * v2.1.0
 * 项目名称回显项目信息/投保人名称回显投保信息
 * */
export const INIT_PROJECT_INSURE_INFO = {
    url: `${BASE_URL}/placeorder-information/backfillInformation`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
export const GET_CREDIT_CODE = {
    url: `${BASE_URL}/channel-risk-control/getSocialCreditCodes`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

// 报告重签
export const REPORT_RE_SIGN = {
    url: `${BASE_URL}/risk-order-re-sign/resign`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

