import { BASE_URL } from '@config'

/**
 * 保前订单
 * */
export const GET_ORDER_LIST = {
    url: `${BASE_URL}/risk-order/getRiskOrderListView`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
export const GET_ORDER_ALL_LIST = {
    url: `${BASE_URL}/risk-order/getRiskOrderListView/all`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
/**
 * 保前订单详情
 * */
export const GET_ORDER_INFO = {
    // url: `${BASE_URL}/risk-order/getRiskOrderDetailView`,
    url: `${BASE_URL}/order/getOrderDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 保前订单详情-查看风控信息数据
 * */
export const GET_ORDER_INFO_RISK_CONTROL_INFO = {
    url: `${BASE_URL}/ro-data-verification-factor-value/getRiskDataVerificationInfoToView`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 保前订单详情-查看关联单
 * */
export const GET_ORDER_RELATION_LIST = {
    url: `${BASE_URL}/risk-order/getRiskOrderRelationList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 保前订单详情-查看承保信息
 * */
export const GET_INSURE_INFO = {
    url: `${BASE_URL}/order/queryInsuranceDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


/**
 * 保前订单详情-黑名单详细信息
 * */
export const GET_BLACK_LIST_OR_WARNING_INFO = {
    url: `${BASE_URL}/order/toViewBlacksOrWrongs`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 保前订单详情-风险评分评级
 * */
export const GET_RISK_ORDER_RATING_LIST = {
    url: `${BASE_URL}/order/getRiskOrderRoRatingList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 保前订单详情-查看日志
 * */
export const GET_ORDER_LOG_LIST = {
    url: `${BASE_URL}/risk-order-log/list`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


/**
 * 资料下载
 * */
// 下载资料/zip 任务模块下接口
export const DOWN_LOAD_FILE = {
    url: `${BASE_URL}/order/downFile`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob'
    // timeout: 30000
}
// pdf导出 返回地址 订单/任务 下载报告
export const DOWN_LOAD_PDF = {
    url: `${BASE_URL}/pdf/downLoadPdf`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
// pdf预览
export const PREVIEW_PDF = {
    url: `${BASE_URL}/pdf/previewPdf`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    responseType: 'blob',
    timeout: 30000
}


//  导出报告
export const EXPORT_ORDER_LIST = {
    url: `${BASE_URL}/risk-order/riskOrderListViewExport/all`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    responseType: 'blob',
    timeout: 30000
}


// 报告重签审核
export const REPORT_RE_SIGN_AUTH = {
    url: `${BASE_URL}/risk-order-re-sign/resignAudit`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 报告重签详情
export const GET_REPORT_RE_SIGN_INFO = {
    url: `${BASE_URL}/risk-order-re-sign/getResignAuditDetail`,
    method: 'GET',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
