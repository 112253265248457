import { BASE_URL } from '@config'

/**
 * 风控采购
 * 提交订单
 * 农民工工资支付履约保证保险/企业版采购
 * */

// 农民工工资支付履约保证保险/企业版采购详情回显
export const GET_WORKERS_ENTERPRISE = {
    url: `${BASE_URL}/workers-enterprise/getChannelOrder`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 农民工工资支付履约保证保险/企业版采购暂存
export const SAVE_WORKERS_ENTERPRISE = {
    url: `${BASE_URL}/workers-enterprise/cache`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 农民工工资支付履约保证保险/企业版采购提交
export const SUBMIT_WORKERS_ENTERPRISE = {
    url: `${BASE_URL}/workers-enterprise`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 农民工工资支付履约保证保险/企业版采购提交
export const EDIT_WORKERS_ENTERPRISE = {
    url: `${BASE_URL}/workers-enterprise/modify`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}


