import { BASE_URL } from '@config'

/**
 * 全部暂存/未提交订单列表
 * */
export const GET_CACHE_LIST = {
    url: `${BASE_URL}/performance-stock/getCacheList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

/**
    删除缓存
 * */
export const DELETE_CACHE = {
    url: `${BASE_URL}/performance-stock/deleteCache`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

/**
    缓存详情
 * */
export const GET_CACHE_INFO = {
    url: `${BASE_URL}/performance-stock/editCache`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
