/**
 * Created by hiro on 2020/07/23.
 * axios 数据拦截
 */

import axios from 'axios'
import { HTTP_ERR_UNAUTHORIZED } from '@/constant/api_status'
import { getToken } from '@utils/auth'
import store from '@store'


let isLogout = false

function loginOut() {

    if (isLogout) {
        return
    }

    isLogout = true

    store.dispatch('logOut')
    // notification.error({
    //     message: '错误',
    //     description: '长时间未操作，请重新登录'
    // })
    // setTimeout(() => {
    //     store.dispatch('logOut')
    // }, 500)
}

axios.interceptors.request.use(function (config) {
    // 获取token
    const TOKEN = getToken()
    if (TOKEN) {
        config.headers['Authorization'] = TOKEN
    }

    // 请求体添加token、version
    // if (config.url !== '/api/user/login') {
    //     const token = getToken()
    //     const version = API_VERSION
    //     config.params={
    //         token,
    //         version,
    //         ...config.params
    //     }
    // }

    window.NProgress.start() // 进度条开始
    return config
})
// 劫持响应数据
axios.interceptors.response.use(function (response) {
    window.NProgress.done()

    if (response.data.code === HTTP_ERR_UNAUTHORIZED) {
        loginOut()
        return
    }
    // 对响应数据做点什么
    return response
}, function (error) {
    window.NProgress.done()
    if (error.response.status === HTTP_ERR_UNAUTHORIZED) {
        loginOut()
        return
    }
    //  处理错误信息
    // let errMsg = throwHTTPError(error)
    // 对响应错误做点什么
    return Promise.reject(error)
})


export default axios
