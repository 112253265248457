import axios from '@service'
import { EXPORT_REPORT_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'
import downloadFileByBlob from '@utils/downloadFileByBlob'
/** *
 导出报告列表
 */
export default async function exportReportListService(payload = {}, config = {}) {
    const param = {
        ...EXPORT_REPORT_LIST,
        ...config,
        data: payload
    }
    const result = await axios(param)
    if (result.status !== HTTP_ERR_SUCCESS) {
        throw new Error( ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
    downloadFileByBlob(result, 'decode')
}
