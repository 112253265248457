import { BASE_URL } from '@config'
/** 商品列表
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 *商品列表-获取商品列表
 */
export const GET_COMMODITY_LIST = {
    url: `${BASE_URL}/product`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *商品列表-获取商品详情
 */
export const GET_COMMODITY_INFO = {
    url: `${BASE_URL}/product/queryProductDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *  商品列表-获取复制商品详情(复制)
 */
export const GET_COPY_COMMODITY_INFO = {
    url: `${BASE_URL}/product/queryCopyProductDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *商品列表-启用或关闭商品
 */
export const OPEN_OR_CLOSE_COMMODITY_SWITCH = {
    url: `${BASE_URL}/product/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 *商品列表-增加商品
 */
export const ADD_COMMODITY = {
    url: `${BASE_URL}/product/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *商品列表-商品修改
 */
export const EDIT_COMMODITY = {
    url: `${BASE_URL}/product/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *商品列表-删除商品
 */
export const DELETE_COMMODITY = {
    url: `${BASE_URL}/product/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 *商品列表-逻辑删除商品
 */
export const LOGIC_DELETE_COMMODITY = {
    url: `${BASE_URL}/product/logic-del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/** *
 * 根据产品分类获取产品参数
 */
export const GET_PRODUCT_PARAMS_BY_PRODUCT_CATEGORY = {
    url: `${BASE_URL}/product-spec/queryProductSpecList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
