import { BASE_URL } from '@config'

/**
 * 风控采购
 * 提交订单
 * 农民工工资支付履约保证保险/项目版采购
 * */

// 农民工工资支付履约保证保险/项目版采购详情回显
export const GET_WORKERS_PROJECT = {
    url: `${BASE_URL}/workers-project/getChannelOrder`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 农民工工资支付履约保证保险/项目版采购暂存
export const SAVE_WORKERS_PROJECT = {
    url: `${BASE_URL}/workers-project/cache`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 农民工工资支付履约保证保险/项目版采购提交
export const SUBMIT_WORKERS_PROJECT = {
    url: `${BASE_URL}/workers-project`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 农民工工资支付履约保证保险/项目版采购提交
export const EDIT_WORKERS_PROJECT = {
    url: `${BASE_URL}/workers-project/modify`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

