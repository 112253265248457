import { BASE_URL } from '@config'

/**
 * 个人任务
 * */
export const GET_PERSON_TASK = {
    url: `${BASE_URL}/task/personalRiskControlOrderTaskStatistics`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
export const GET_ALL_TASK_LIST = {
    url: `${BASE_URL}/task/orderStatusOverview`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

/**
 * 消息中心
 * */
export const GET_MESSAGE_LIST = {
    url: `${BASE_URL}/message`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
export const BATCH_ALL_MESSAGE = {
    url: `${BASE_URL}/message/batchRead`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
export const BATCH_ONE_MESSAGE = {
    url: `${BASE_URL}/message/readOne/`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
