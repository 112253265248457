import axios from '@service'
import { EXPORT_ALL_ORDER_LIST } from './api'
import downloadFileByBlob from '@utils/downloadFileByBlob'

export default async function exportOrderListService(payload = {}, config = {}) {
    const param = {
        ...EXPORT_ALL_ORDER_LIST,
        ...config,
        data: payload
    }
    const res = await axios(param)
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        let result = null
        reader.readAsText(res.data, 'utf-8')
        reader.onload = e => {
            try {
                result = JSON.parse(e.target.result)
                reject(result.message)
            } catch (err) { // 正常下载
                downloadFileByBlob(res, 'decode')
                resolve()
            }
        }
    })

}
