import { BASE_URL } from '@config'

/**
 * 对接工保云
 *查询主数据（风险对象）
 * */
//
export const GET_LIST_MASTER_DATA = {
    url: `${BASE_URL}/gbcloud/listMasterData`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 查询分类（风险分类）
export const GET_CLOUD_RISK_CATEGORY = {
    url: `${BASE_URL}/gbcloud/listClassification`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 查询风险因子列表
export const GET_CLOUD_RISK_FACTOR_LIST = {
    url: `${BASE_URL}/gbcloud/listRiskFactorDefinition`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 查询字段（风险因子） 未添加的
export const GET_CLOUD_RISK_FACTOR_NOT_ADD_LIST = {
    url: `${BASE_URL}/gbcloud/listFieldNotAdded`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}

// 业务对象
export const GET_CLOUD_BUSINESS_OBJECT_LIST = {
    url: `${BASE_URL}/gbcloud/listAllEnterpriseAndProjectBusinessObject`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 因子属性值(字典常量)
export const GET_FACTOR_ATTRIBUTES = {
    url: `${BASE_URL}/gbcloud/listDictionaryConstants/`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 词典
export const GET_LIST_DICTIONARY = {
    url: `${BASE_URL}/gbcloud/listDictionary`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
