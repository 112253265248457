import axios from '@service'
import { INIT_PROJECT_INSURE_INFO } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/**
 * v2.1.0
 * 项目名称回显项目信息/投保人名称回显投保信息
 * */
export default async function initProjectInsureInfoService(payload = {}, config = {}) {
    const param = {
        ...INIT_PROJECT_INSURE_INFO,
        ...config,
        data: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
