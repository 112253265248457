import axios from '@service'
import { DOWN_LOAD_PDF } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/**
 * 风控采购订单下载报告
 * */
export default async function downLoadPdfService(payload = {}, config = {}) {
    const param = {
        ...DOWN_LOAD_PDF,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
