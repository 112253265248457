import axios from '@service'
import { SUBMIT_CONTRACT_PERFORMANCE } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

export default async function submitContractPerformanceService(payload = {}, config = {}) {
    const param = {
        ...SUBMIT_CONTRACT_PERFORMANCE,
        ...config,
        data: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
