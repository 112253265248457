import axios from '@service'
import { PREVIEW_PDF } from './api'
import { message } from 'ant-design-vue'

/**
 *
 预览pdf
 */
export default async function previewLoadPdfService(payload = {}, config = {}) {
    const param = {
        ...PREVIEW_PDF,
        ...config,
        params: payload
    }
    const res = await axios(param)
    return new Promise(resolve => {
        let reader = new FileReader()
        let result = null
        reader.readAsText(res.data, 'utf-8')
        reader.onload = e => {
            try {
                result = JSON.parse(e.target.result)
                result.message && message.error(result.message) // 报错信息
                resolve('')
            } catch (err) { // 正常下载
                const { data } = res
                const blob = new Blob([data], {
                    type: 'application/pdf;'
                })
                const href = window.URL.createObjectURL(blob)
                resolve(href)
            }
        }
    })
}
