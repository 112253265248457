import { BASE_URL } from '@config'
/**
 * 建设工程预付款履约保证保险
 * */
// 建设工程预付款履约保证保险详情回显
export const GET_API = {
    url: `${BASE_URL}/advancePayment/getChannelOrder`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 建设工程预付款履约保证保险暂存
export const SAVE_API = {
    url: `${BASE_URL}/advancePayment/cache`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 建设工程预付款履约保证保险修改
export const EDIT_API = {
    url: `${BASE_URL}/advancePayment/modify`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

// 建设工程预付款履约保证保险提交
export const SUBMIT_API = {
    url: `${BASE_URL}/advancePayment`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

