import { BASE_URL } from '@config'

/**
 * 风控报告
 * */
// 保前报告列表
export const GET_REPORT_BEFORE_LIST = {
    url: `${BASE_URL}/risk-order/getRiskOrderReportList/all`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 保前报告详情 差接口
export const GET_REPORT_BEFORE_INFO = {
    url: `${BASE_URL}/order/getRiskOrderReportDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 导出保前报告
export const EXPORT_REPORT_LIST = {
    url: `${BASE_URL}/risk-order/riskOrderReportViewExport/all`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    responseType: 'blob',
    timeout: 30000
}
