import { BASE_URL } from '@config'

/**
 * 审批及日志
 * */
// 日志列表
export const GET_LOG_LIST = {
    url: `${BASE_URL}/operationLog/selectModelOperationLog`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 数据核验模型审核
export const AUDIT_DATA_CHECK = {
    url: `${BASE_URL}/data-verification-model/dataVerificationApproval`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 评分模型审核
export const AUDIT_GRADE_MODEL = {
    url: `${BASE_URL}/scoring-model/scoringModelApproval`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 风险评估模型审核
export const AUDIT_RISK_ASSESS = {
    url: `${BASE_URL}/risk-assessment-model/riskAssessmentApproval`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}

// 风险等级审核
export const AUDIT_RISK_CLASS = {
    url: `${BASE_URL}/risk-score-model/riskScoreModelApproval`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 智能评估审核
export const AUDIT_SMART_ASSESS = {
    url: `${BASE_URL}/intelligent-evaluation-model/evaluationModelApproval`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
// 规则集审核
export const AUDIT_RULE_SET = {
    url: `${BASE_URL}/rule-set/approval`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}


/**
 * 商品审核
* */
// 商品列表
export const GET_COMMODITY_LIST = {
    url: `${BASE_URL}/productAudit`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 商品详情
export const GET_COMMODITY_INFO = {
    url: `${BASE_URL}/productAudit/queryProductDetail`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
// 添加审核
export const AUDIT_COMMODITY = {
    url: `${BASE_URL}/productAudit/auditProduct`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json;'
    },
    timeout: 30000
}
