import { BASE_URL } from '@config'

/** 基础查询接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 用户登录接口
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_OAUTH_TOKEN = {
    // url: `${BASE_URL}/user-center/login`,
    url: `${BASE_URL}/tong/login`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}
/**
 * 用户退出接口
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const LOGOUT = {
    url: `${BASE_URL}/tong/logout`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}


/**
 * 获取用户信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_INFO = {
    // url: `${BASE_URL}/user-center/getUserDetail`,
    url: `${BASE_URL}/tong/getUserDetail`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


/**
 * 获取用户menu
 */
export const GET_USER_MENU = {
    // url: `${BASE_URL}/user-center/getMenu`,
    url: `${BASE_URL}/tong/getMenu`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 地区树
 *  **/
export const GET_AREA_TREE = {
    url: `${BASE_URL}/area/getAreaTree`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 上传接口*/
export const UPLOAD_FILE = {
    url: `${BASE_URL}/oss`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 保司列表
 * */
export const GET_SYS_DICT_LIST = {
    url: `${BASE_URL}/g-sys-dict/list`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
