import { BASE_URL } from '@config'

/** 商品管理
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 *商品管理-产品分类树
 */
export const GET_CATEGORY_TREE = {
    url: `${BASE_URL}/risk-category/tree/`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 *商品管理-产品分类列表 分页
 */
export const GET_CATEGORY_LIST = {
    url: `${BASE_URL}/risk-category`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 *商品管理-添加产品分类
 */
export const ADD_CATEGORY = {
    url: `${BASE_URL}/risk-category/save`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *商品管理-修改产品分类
 */
export const EDIT_CATEGORY = {
    url: `${BASE_URL}/risk-category/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *商品管理-删除产品分类
 */
export const DELETE_CATEGORY = {
    url: `${BASE_URL}/risk-category/del/`,
    method: 'delete',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 *商品管理-启用或者关闭产品
 */
export const OPEN_OR_CLOSE_PRODUCT_CATEGORY = {
    url: `${BASE_URL}/risk-category/enableSwitch`,
    method: 'put',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 *商品管理-保前风控参数保存
 */
export const ADD_RISK_CATEGORY_PROSPEC = {
    url: `${BASE_URL}/product-spec-application-configuration/saveRiskCategoryProspec`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
/**
 *商品管理-根据产品分类id获取回显参数
 */
export const GET_PARAMS_BY_CATEGORY = {
    url: `${BASE_URL}/product-spec/queryProductSpecList`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
/**
 * 给产品分类设置险种编号
 * */
export const SET_INSURE_TYPE = {
    url: `${BASE_URL}/product-spec-application-configuration/saveInsuranceType`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 30000
}
