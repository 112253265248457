import axios from '@service'
import { DOWN_LOAD_CREDIT_REPORT_FILE } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'
import downloadFileByBlob from '@utils/downloadFileByBlob'

/**
 * 征信报告列表-下载资料影像
 */
export default async function downloadCreditReportFileService(params, config = {}) {
    const param = {
        ...DOWN_LOAD_CREDIT_REPORT_FILE,
        ...config,
        params
    }
    const result = await axios(param)
    if (result.status !== HTTP_ERR_SUCCESS) {
        throw new Error( ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
    downloadFileByBlob(result)
}
