import axios from '@service'
import { REPORT_RE_SIGN } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/**
 * 风控采购订单 报告重签
 * */
export default async function reportReSignService(payload = {}, config = {}) {
    const param = {
        ...REPORT_RE_SIGN,
        ...config,
        data: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
