import axios from '@service'
import { GET_MESSAGE_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/**
 * 所有消息
 */
export default async function getMessageListService(payload = {}, config = {}) {
    const param = {
        ...GET_MESSAGE_LIST,
        ...config,
        params: payload
    }
    try {
        const { data } = await axios(param)
        if (data.code === HTTP_ERR_SUCCESS) {
            return data.data
        } else {
            throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
        }
    } catch (e) {
        throw new Error(e.message)
    }

}
