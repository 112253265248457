import axios from './index'
import { LOGOUT } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'


/**
 * 用户退出登录
 * @returns {Promise<*>}
 */
export default async function () {
    const { data } = await axios(LOGOUT)
    if (data.code !== HTTP_ERR_SUCCESS) {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
