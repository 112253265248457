import axios from './index'
import { GET_AREA_TREE } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'


let dataRedis = null
/**
 * 获取地区信息
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getAreaTreeService(payload = {}, config = {}) {
    if (dataRedis) {
        return dataRedis
    }
    const param = {
        ...GET_AREA_TREE,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        dataRedis = data.data
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
