import axios from '@service'
import { GET_REPORT_RE_SIGN_INFO } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/**
 * 风控采购订单 报告重签详情
 * */
export default async function getReportReSignInfoService(payload = {}, config = {}) {
    const param = {
        ...GET_REPORT_RE_SIGN_INFO,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
