import axios from '@service'
import { GET_COPY_COMMODITY_INFO } from './api'
import { HTTP_ERR_SUCCESS } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'


/**
 * 复制商品详情
 * @param payload
 * @param config
 * @returns {Promise<*>}
 */
export default async function getCopyCommodityInfoService(payload = {}, config = {}) {
    const param = {
        ...GET_COPY_COMMODITY_INFO,
        ...config,
        params: payload
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data.data
    } else {
        throw new Error(data.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
